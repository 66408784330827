import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "Home view" */ "../views/HomeView.vue"),
  },
  {
    path: "/university/:university/",
    name: "University",
    component: () =>
      import(
        /* webpackChunkName: "university view" */ "../views/UniversityView.vue"
      ),
    props: (router) => ({
      university: router.params.university,
    }),
  },
  {
    path: "/:university/:category/:program/:elective/",
    name: "Elective",
    component: () =>
      import(
        /* webpackChunkName: "Elective view" */ "../views/ElectiveView.vue"
      ),
    props: true,
  },
  {
    path: "/about-us/",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/AboutUs.vue"),
  },
  {
    path: "/contact-us/",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "Contact Us" */ "../views/ContactUs.vue"),
  },
  {
    path: "/courses/:program/:university/",
    name: "UniBasedElective",
    component: () =>
      import(
        /* webpackChunkName: "UniversityBased view" */ "../views/ProgramBasedElectiveView.vue"
      ),
    props: true,
  },
  {
    path: "/:category/:program/",
    name: "ProBasedElective",
    component: () =>
      import(
        /* webpackChunkName: "ProgramBased view" */ "../views/OverallElectiveView.vue"
      ),
    props: true,
  },
  {
    path: "/university/",
    name: "OverallUniversities",
    component: () =>
      import(
        /* webpackChunkName: "Overall university view" */ "../views/OverallUniversityView.vue"
      ),
  },
  {
    path: "/:category/electives/",
    name: "CatBasedElective",
    component: () =>
      import(
        /* webpackChunkName: "ProgramBased view" */ "../views/ExploreAllProgramsView.vue"
      ),
    props: true,
  },
  {
    path: "/experience/centre/",
    name: "ExperienceCenter",
    component: () =>
      import(
        /* webpackChunkName: "ProgramBased view" */ "../views/ExperienceCentre.vue"
      ),
    props: true,
  },
  {
    path: "/student-leads/",
    name: "StudentLeads",
    component: () =>
      import(
        /* webpackChunkName: "ProgramBased view" */ "../views/StudentLeads.vue"
      ),
    props: true,
  },
  {
    path: "/blogs/:category/:blog/",
    name: "SelectedBlog",
    component: () =>
      import(/* webpackChunkName: "blogView" */ "../views/BlogsView.vue"),
    props: true,
  },
  {
    path: "/blogs/:category?/",
    name: "Blogs",
    component: () =>
      import(/* webpackChunkName: "blog list" */ "../views/BlogsListing.vue"),
    props: true,
  },
  {
    path: "/news/",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/NewsEvents.vue"),
  },
  {
    path: "/events/:slug/",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "blog list" */ "../views/EventsView.vue"),
    props: true,
  },
  {
    path: "/privacy-policy/",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "Privacy Policy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/:partners/",
    name: "Partners",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/PartnersView.vue"),
    props: true,
  },
  {
    path: "/:city/thankyou",
    name: "ThankyouPune",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "thankyou" */ "../views/PuneThankyouView.vue"
      ),
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: () =>
      import(/* webpackChunkName: "thankyou" */ "../views/ThankyouView.vue"),
  },
  {
    path: "/lp/jain-online-degree-programs",
    name: "JainOnlineJustDial",
    component: () =>
      import(
        /* webpackChunkName: "justDial" */ "../views/JustDial/JainOnlineJustDialView.vue"
      ),
  },
  {
    path: "/lp/jain-online-degree-programs/thank-you",
    name: "JainOnlineJustDialThankYou",
    component: () =>
      import(
        /* webpackChunkName: "justDial" */ "../views/JustDial/JainOnlineJustDailThankYou.vue"
      ),
  },
  {
    path: "/:partners/privacy-policy/",
    name: "PartnersPrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "Partners Privacy Policy" */ "../views/PartnersPrivacyPolicy.vue"
      ),
    props: true,
  },
  // {
  //   path: "/learn-at-campus/",
  //   name: "LearnAtCampus",
  //   component: () =>
  //     import(/* webpackChunkName: "Learn at Campus" */ "../views/LearnAtCampus.vue"),
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () =>
      import(
        /* webpackChunkName: "Page Not Found" */ "../views/PageNotFound.vue"
      ),
  },
  // { path: "*", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0,
      behavior: "smooth",
    };
  },
});

export default router;
