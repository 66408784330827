import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "jquery";
import { Plugin } from "vue-responsive-video-background-player";
import VueGtag from "vue-gtag";

const app = createApp(App);

let globe = ["/pune/", "/pune", "/pune/thankyou/", "/pune/thankyou"];

if (!globe.includes(window.location.pathname)) {
  app.use(VueGtag, {
    config: { id: "GTM-PJXHHX7" },
  });
}
// config: { id: "AW-11303512183" }, old Gtag
let puneLocation = ["/pune/", "/pune"];
let puneThankyouPage = ["/pune/thankyou/", "/pune/thankyou"];
if (puneLocation.includes(window.location.pathname)) {
  app.use(VueGtag, {
    includes: [{ id: "AW-11483502594" }, { id: "AW-11303512183" }],
    config: { id: "AW-11483502594" },
  });
}

if (puneThankyouPage.includes(window.location.pathname)) {
  app.use(VueGtag, {
    config: { id: "AW-11483502594" },
  });
}

app.use(store).use(router).use(Plugin).mount("#app");
